/***
 * @author Harma Davtian
 * @desc js library to house some common functions
 */

var hcpv_rpa_util = function(){

    var calcEstimateMonthlyPayment = function(tradein, apr, currentTerm, listCarEl$, dp) {

        listCarEl$.each(function() {
            let calc,downPayment;
            var price = parseFloat($(this).text().replace(/[^\w\s]/gi, '')); // remove special characters
            if(!dp) {
                downPayment = price / 10;
            }else {
                downPayment = dp;
            }
            var totalDown  = downPayment + tradein;
            var monInt = apr / 1200;

            if(price - tradein - downPayment < 5000) {
                calc = '--';
            }else {
                calc = ((monInt + (monInt / (Math.pow((1 + monInt), parseInt(currentTerm)) -1))) * (price - (totalDown || 0))).toFixed();
            }
            $(this).parent().parent().find(".est-mo-payment .mp").text(calc);
        });
    }

    var getParameterByName = function(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)','i'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
    
    var dataSource = function() {
        var usedev = getParameterByName('usedev');
        var dataURL = '';
            switch (usedev) {
            case '1': 
                dataURL = 'https://hondacertifieddev.rpa-dev.com';
                break;
            case '2':
                dataURL = 'https://hondacertifieddev2.rpa-dev.com';
                break;
            }
            return dataURL;
    }

    var isPhone = function(){
        if( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            //|| navigator.userAgent.match(/iPad/i)
            //|| navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ){
            return true;
        }
        else {
            return false;
        }
    };

    var GenError = function(_c){

        var $el = _c.container;

        var template_A = function(_msg, _errorCode, _closeButton){
            var markup = "";
            markup += '<div class="generic-page-error">';
            markup += '<span class="error-description">' + _msg + '</span>';

            // include-or-not an error code
            if (_errorCode != undefined && _errorCode !=null & _errorCode !="") {
                markup += '<span class="error-code"> (' + _errorCode + ')</span>';
            };

            // include-or-not a close button
            if (_closeButton) {
                markup += '<a href="javascript:void(0)" class="close-btn"></a>';
            }
            markup += '</div>';

            return markup;
        };

        var templatePicker = function(template){
            switch(template){
                case 'A':
                    return template_A;
                    // Add future templates here
                    break;
            };
        };

        var setMessage = function(_config){

            var msg = _config.msg || '',
                errorCode = _config.errorCode || '',
                closeButton = _config.closeButton || false,
                template = _config.template || 'A';

            // pick a template
            var content = templatePicker(template)(msg, errorCode, closeButton);

            $el.html(content);

            // close button click
            $el.find('.close-btn').on('click',function(){$(this).parent().remove()});

            // now show the error, because by default the css sets errors to off, we only show when necessary
            $el.find('.generic-page-error').css('display','block');

        };

        var clearMessage = function(){
          $el.html("");
        };

        return {
            setMessage: setMessage,
            clearMessage: clearMessage
        };

    };

    var validateEmail = function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    var validateZipCode = function validateZipCode(zip) {
        return /^\d{5}$/.test(zip);
    }

    // This code will check if user has Adblocker enabled or not

    return {
        isPhone: isPhone,
        GenError: GenError,
        validateEmail: validateEmail,
        validateZipCode: validateZipCode,
        dataSource: dataSource,
        calcEstimateMonthlyPayment
    };

}();

